import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from '../../services/alert.service';
import { StepService } from '../../services/step.service';
import { StepModel } from '../../enums/step-model';
import { UniversalTransmitUtils } from '../../utils/universal-transmit-utils';
import { environment } from 'src/environments/environment';
import { UniversalSessionService } from '../../services/universal-session.service';
import { UniversalAnalyticsService } from '../../services/universal-analytics.service';
import { SsoUtils } from '../../utils/sso-utils';

@Component({
  selector: 'app-ac-select-otp',
  templateUrl: './ac-select-otp.component.html',
  styleUrls: ['./ac-select-otp.component.scss']
})
export class AcSelectOtpComponent implements OnInit, AfterViewInit {
  @Input() step: StepModel;
  showError = false;
  public submitted = false;
  public loading = false;
  public securityForm: UntypedFormGroup;
  public contactMessage: any = environment.contactMessage;
  public phoneNumber1: string;
  public phoneNumber2: string;

  public application: string;
  stepIndex;

  public otpTargets: any[];
  public otpTargetsMap: Map<string, com.ts.mobile.sdk.AuthenticatorTarget> = new Map();
  onTargetSelected: (target: com.ts.mobile.sdk.AuthenticatorTarget | undefined) => void;
  public onCancel: () => void;

  public contactEmail;
  public contactMobileVoice;
  public contactMobileText;
  public contactLandVoice;

  private pageName = 'addCredentialOtp';
  private analyticsSessionId = '';
  private analyticsUserId = '';
  private isAuthenticated = 'n';
  public isAndroid = false;

  @ViewChild('tempPassword', { read: ElementRef }) firstField: ElementRef;
  isStep2 = false;
  isCancel = false;

  constructor(private router: Router,
              private readonly formBuilder: UntypedFormBuilder,
              private stepsService: StepService,
              private alertService: AlertService,
              private transmitUtils: UniversalTransmitUtils,
              private utils: SsoUtils,
              private sessionService: UniversalSessionService,
              private analyticsService: UniversalAnalyticsService) {
    this.phoneNumber1 = this.removeChars(this.contactMessage.phone1);
    this.phoneNumber2 = this.removeChars(this.contactMessage.phone2);
    this.isAndroid = this.utils.isAndroid();
  }

  ngOnInit() {
    this.createForm();
    this.stepsService.getCurrentStep().subscribe(step => {
      this.stepIndex = step.stepIndex;
    });

    // Initialize analytics
    const data = this.sessionService.getSessionData(this.pageName);
    this.analyticsSessionId = data.analyticsSessionId;
    this.analyticsUserId = data.analyticsUserId;
    this.isAuthenticated = data.isAuthenticated;
  }

  ngAfterViewInit(): void {
    if (!this.analyticsService.isInitialized()) {
      this.analyticsService.init(this.analyticsSessionId, this.analyticsUserId, '');
    } else {
      this.resetAnalytics();
    }
  }

  /**
   * remove all characters such as dash and parentheses so that
   * only digits remain
   * @param phoneNumber Full phone number with dashes and more
   */
  removeChars(phoneNumber): string {
    return phoneNumber.replace(/[^0-9]/g, '');
  }

  createForm(){
    if (this.contactEmail) {
      this.securityForm = this.formBuilder.group({
        contactType: new UntypedFormControl('email', Validators.required)
      });
    } else {
      this.securityForm = this.formBuilder.group({
        contactType: new UntypedFormControl('text', Validators.required)
      });
    }
  }

  get f() {
    return this.securityForm.controls;
  }

  onSubmit(){
    // console.warn('contactType=', this.f.contactType.value);
    this.clearAlertMessage();
    const contactType = this.f.contactType.value;

    this.submitted = true;
    if (this.securityForm.invalid) {
      return;
    } else {
      // this.isStep2 = true;
      this.analyticsService.setOtpUsed('otp-addcredential');
      this.analyticsService.setOptOption(`otp-${contactType}`);
      this.onTargetSelected(this.otpTargetsMap.get(contactType));
    }
  }

  back(){
    if (this.onCancel) {
      this.onCancel();
    }
  }

  public setTargets = (targets: Array<com.ts.mobile.sdk.AuthenticatorTarget>) => {
    this.otpTargets = targets;
    this.otpTargets.forEach((target) => {
      if (target._channel === 1) {
        this.otpTargetsMap.set('text', target);
        this.contactMobileText = target._description;
      }
      if (target._channel === 4 && target._targetIdentifier == '1') {
        this.otpTargetsMap.set('mobileVoice', target);
        this.contactMobileVoice = target._description;
      }
      if (target._channel === 4 && target._targetIdentifier == '2') {
        this.otpTargetsMap.set('landVoice', target);
        this.contactLandVoice = target._description;
      }
      if (target._channel === 2) {
        this.otpTargetsMap.set('email', target);
        this.contactEmail = target._description;
      }
    });
  }

  showOTPLimitExceeded() {
    this.alertService.error(this.transmitUtils.getOTPLimitExceededErrorMessage());
  }

  showTargetSelectionErrorMessage() {
    this.showError = true;
  }

  hideTargetSelectionErrorMessage() {
    this.showError = false;
  }

  clearAlertMessage() {
    this.alertService.hide();
  }

  resetAnalytics() {
    const analyticsJson = {
      cbpgType: 'addcredential',
      cbaction: 'view',
      cbpgFunction: 'verifyidentity',
      cbpgSubFunction: 'selectotp',
      cbpgName: 'view',
      cbsessionId: this.analyticsSessionId,
      cbaccType: '',
      cbCisKey: this.analyticsUserId,
      cbauthenticated: this.isAuthenticated
    };
    this.analyticsService.updateAnalytics(analyticsJson);
  }
}
