import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UniversalSessionService } from '../services/universal-session.service';
import { SpinnerService } from '../../core/services/spinner.service';

declare var clientLib: any;

@Injectable({
  providedIn: 'root'
})
export class ThreatMetrixUniversalService {

  private apiUrl = environment.apiUrl;
  private appName: string;
  private scriptLoaded = false;
  private threatMetrixUrl: string = environment.threatMetrixUrl;
  private threatMetrixProfileDomain: string = environment.threatMetrixProfileDomain;
  private orgId: string = environment.orgId;
  private tmxUnivSessionId: string;
  private application: string;

  constructor(private sessionService: UniversalSessionService,
              private spinnerService: SpinnerService) {

  }

  initThreatMetrixService(appName): string {
    this.appName = appName;
    return this.generateTmxUnivSessionId();
  }

  getTmxUnivSessionID() {
    return this.tmxUnivSessionId;
  }

  addEnhancedProfilingScriptTag(tmxUniSessionId): Promise<any> {
    return new Promise((resolve, reject) => {
      if (document.getElementById('threatMetrixUnivEnP') != null) {
        const elem: any = document.getElementById('threatMetrixUnivEnP');
        elem.parentElement.removeChild(elem);
      }
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'threatMetrixUnivEnP';
      const inlineCode = document.createTextNode(`clientLib.profile("${this.threatMetrixProfileDomain}","${this.orgId}","${tmxUniSessionId}");`);
      script.appendChild(inlineCode);
      document.body.appendChild(script);

      script.onload = () => {
        this.scriptLoaded = true;
        resolve({loaded: true});
      };
      script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
    });
  }

  generateTmxUnivSessionId() {
    const sessionIdRandom = Math.floor(Math.random() * 100000000);
    const end = UniversalSessionService.generateTimeString();
    this.tmxUnivSessionId = `tm-${sessionIdRandom}-${end}`;
    return this.tmxUnivSessionId;
  }

}
