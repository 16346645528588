import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../../services/alert.service';
import { SpinnerService } from '../../services/spinner.service';
import { SsoUtils } from '../../utils/sso-utils';
import { UniversalTransmitUtils } from '../../utils/universal-transmit-utils';
import { environment } from 'src/environments/environment';
import { UniversalSessionService } from '../../services/universal-session.service';
import { UniversalAnalyticsService } from '../../services/universal-analytics.service';

@Component({
  selector: 'app-otp-selection',
  templateUrl: './otp-selection.component.html',
  styleUrls: ['./otp-selection.component.scss']
})
export class OtpSelectionComponent implements OnInit, AfterViewInit {

  public submitted = false;
  public loading = false;
  public otpSelectionForm: UntypedFormGroup;
  resetEnabled: boolean;
  showError = false;
  public hide = true;
  public contactMessage: any = environment.contactMessage;
  public phoneNumber1: string;
  public phoneNumber2: string;
  public isAndroid = false;
  public application: string;
  public previousUrl: string;
  private pageName = 'otpSelection';
  private customerId: string;
  private analyticsSessionId = '';
  private analyticsUserId = '';
  private previousPageName = '';
  private isAuthenticated = 'n';

  @ViewChild('email') firstField: ElementRef;

  public otpTargets: any[];
  public otpTargetsMap: Map<string, com.ts.mobile.sdk.AuthenticatorTarget> = new Map();
  onTargetSelected: (target: com.ts.mobile.sdk.AuthenticatorTarget | undefined) => void;
  public onCancel: () => void;

  public contactEmail;
  public contactMobileVoice;
  public contactMobileText;
  public contactLandVoice;
  public checkTLIComponent = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private sessionService: UniversalSessionService,
              private readonly formBuilder: UntypedFormBuilder,
              private utils: SsoUtils,
              private spinnerService: SpinnerService,
              private alertService: AlertService,
              private transmitUtils: UniversalTransmitUtils,
              private analyticsService: UniversalAnalyticsService) {
    this.phoneNumber1 = this.utils.removeChars(this.contactMessage.phone1);
    this.phoneNumber2 = this.utils.removeChars(this.contactMessage.phone2);
    this.isAndroid = this.utils.isAndroid();
  }

  ngOnInit() {
    this.createForm();
    // Initialize analytics do NOT set a previousPageName!
    const data = this.sessionService.getSessionData(this.pageName);
    this.customerId = data.customerId;
    this.analyticsSessionId = data.analyticsSessionId;
    this.analyticsUserId = data.analyticsUserId;
    this.previousPageName = data.previousPageName;
    this.isAuthenticated = data.isAuthenticated;
  }

  ngAfterViewInit(): void {
    if (this.utils.shouldFocus()){
      this.firstField.nativeElement.focus();
    }
    if (!this.analyticsService.isInitialized()) {
      this.analyticsService.init(this.analyticsSessionId, this.analyticsUserId, '');
    } else {
      this.resetAnalytics();
    }
  }

  resetAnalytics() {
    const analyticsJson = {
      cbpgType: this.previousPageName,
      cbaction: 'view',
      cbpgFunction: 'otpverification-selectotp',
      cbpgSubFunction: '',
      cbpgName: 'view',
      cbsessionId: this.analyticsSessionId,
      cbaccType: '',
      cbCisKey: this.analyticsUserId,
      cbauthenticated: this.isAuthenticated
    };
    this.analyticsService.updateAnalytics(analyticsJson);
  }

  /**
   * Create the form and set the default value to 'email'
   * check if email exists else default will be 'text'
   */
  createForm() {
    if (this.contactEmail) {
      this.otpSelectionForm = this.formBuilder.group({
        contactType: new UntypedFormControl('email', Validators.required)
      });
    } else {
      this.otpSelectionForm = this.formBuilder.group({
        contactType: new UntypedFormControl('text', Validators.required)
      });
    }
  }

  get f() {
    return this.otpSelectionForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    this.clearAlertMessage();
    const contactType = this.f.contactType.value;

    if (this.otpSelectionForm.invalid) {
      return;
    } else {
      this.analyticsService.setOtpUsed(`otp-${this.previousPageName}`);
      this.analyticsService.setOptOption(`otp-${contactType}`);
      this.onTargetSelected(this.otpTargetsMap.get(contactType));
    }
  }

  back(evt){
    evt.preventDefault();
    if (this.onCancel) {
      this.onCancel();
    }
  }

  public setTargets = (targets: Array<com.ts.mobile.sdk.AuthenticatorTarget>) => {
    this.otpTargets = targets;
    this.otpTargets.forEach((target) => {
      if (target._channel === 1) {
        this.otpTargetsMap.set('text', target);
        this.contactMobileText = target._description;
      }
      if (target._channel === 4 && target._targetIdentifier == '1') {
        this.otpTargetsMap.set('mobileVoice', target);
        this.contactMobileVoice = target._description;
      }
      if (target._channel === 4 && target._targetIdentifier == '2') {
        this.otpTargetsMap.set('landVoice', target);
        this.contactLandVoice = target._description;
      }
      if (target._channel === 2) {
        this.otpTargetsMap.set('email', target);
        this.contactEmail = target._description;
      }
    });
  }

  showOTPLimitExceeded() {
    this.alertService.error(this.transmitUtils.getOTPLimitExceededErrorMessage());
  }

  showTargetSelectionErrorMessage() {
    this.showError = true;
  }

  hideTargetSelectionErrorMessage() {
    this.showError = false;
  }

  clearAlertMessage() {
    this.alertService.hide();
  }
}
