import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { AlertService } from '../services/alert.service';
import { SpinnerService } from '../services/spinner.service';
import { UniversalTransmitUtils } from './universal-transmit-utils';
import { universalErrorCodes } from '../errorConfig/universal-error-codes.config';
import { NgxSsoSpinnerService } from '@citizens-digital/ngx-sso-lib/ngx-sso-lib';

@Injectable()
export class SsoUtils {

  constructor(private alertService: AlertService,
              private spinnerService: NgxSsoSpinnerService,
              private transmitUtils: UniversalTransmitUtils) {}

  /**
   * remove all characters such as dash and parentheses so that
   * only digits remain mainly used for phone numbers
   * @param phoneNumber Full phone number with dashes and more
   */
  public removeChars(phoneNumber): string {
    return phoneNumber.replace(/[^0-9]/g, '');
  }

  public toCamelCase(applicationName: string): string {
    const lowerCaseAppName = applicationName.toLowerCase();
    if (lowerCaseAppName.indexOf('access') !== -1) {
      const firstPart = lowerCaseAppName.substr(0, 6);
      const lastPart = lowerCaseAppName.substr(6 + 1);

      return firstPart + lowerCaseAppName.charAt(6).toUpperCase() + lastPart;
    }
    return lowerCaseAppName;
  }

  encryptUsingAES(toBeEncrypted: string): string {
    const _key = CryptoJS.enc.Utf8.parse(environment.aesVariable);
    const _iv = CryptoJS.enc.Utf8.parse(environment.aesVariable);
    const encrypted = CryptoJS.AES.encrypt(
      toBeEncrypted, _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    return encrypted.toString();
  }

  decryptUsingAES(toBeDecrypted: string): string {
    const _key = CryptoJS.enc.Utf8.parse(environment.aesVariable);
    const _iv = CryptoJS.enc.Utf8.parse(environment.aesVariable);

    const decrypted = CryptoJS.AES.decrypt(
      toBeDecrypted, _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8);
    return decrypted;
  }

  formatPhoneNumberForUSAndOtherCountries(countries: [], countryCode: string, phoneNumber: string) {
    let code;
    if (countryCode == undefined) {
      code = '';
    } else {
      code = this.getPhoneCodeForCountryCode(countries, countryCode);
    }
    return this.formatPhoneNumberWithCountryCode(code, phoneNumber);
  }

  formatPhoneNumberWithCountryCode(code: string, phoneNumber: string) {
    phoneNumber = phoneNumber.replace(/\-/g, '');
    code = code.replace(/\-/g, '');

    if ((code == '' || code == '1') && phoneNumber.length == 10) {
      const areaCodeStr = phoneNumber.slice(0, 3);
      const midSectionStr = phoneNumber.slice(3, 6);
      const lastSectionStr = phoneNumber.slice(6);
      return `${areaCodeStr}-${midSectionStr}-${lastSectionStr}`;
    } else if (code == '') {
      return phoneNumber;
    } else {
      return `(${code})${phoneNumber}`;
    }
  }

  formatPhoneNumberForTransmitCall(countries: [], countryCode: string, phoneNumber: string) {
    let code = this.getPhoneCodeForCountryCode(countries, countryCode);
    phoneNumber = phoneNumber.replace(/\-/g, '');
    return `(${code.replace(/[^\w ]/g, '')}) ${phoneNumber}`;
  }

  getPhoneCodeForCountryCode(countries: [], countryCode: string) {
    let val = '';
    countries.forEach(element => {
      if (element['countryCode'] == countryCode.toLowerCase()) {
        val = this.removeSpecialCharacters(element['code']);
      }
    });
    return val;
  }

  getCountryCodeFromPhoneCode(countries: [], phnonecode: string) {
    let val = '';
    countries.forEach(element => {
      if (this.removeSpecialCharacters(element['code']) == phnonecode) {
        val = this.removeSpecialCharacters(element['countryCode']);
      }
    });
    return val;
  }

  removeSpecialCharacters(oldStr: string): string {
    if (oldStr == undefined) {
      return '';
    }
    return (oldStr.replace(/[^\w ]/g, '')).replace(/\s+/g, ''); // removes special character and spaces
  }

  formatOTPTarget(targetType: string, targetVal: string) {
    if (targetType == 'EMAIL') {
      let indexOfAt = targetVal.indexOf('@');
      return targetVal.substr(0, 1) + '...' + targetVal.substr(indexOfAt);
    } else if (targetType == 'PHONE' || targetType == 'VOICE') {
      if (targetVal.length <= 4) {
        return targetVal;
      } else {
        const replaceWithStar = targetVal.slice(0, targetVal.length - 4);
        const lastFourDigits = targetVal.slice(-4);

        const newTargetVal = replaceWithStar.replace(/[a-zA-Z0-9]/gi, '*') + lastFourDigits;
        return newTargetVal;
      }
    }
  }

  isAndroid(): boolean {
    const os = this.getMobileOperatingSystem();
    return os === 'Android';
  }

  isIOS(): boolean {
    const os = this.getMobileOperatingSystem();
    return os === 'iOS';
  }

  isSafari() {
    const userAgent = navigator.userAgent.toLowerCase();
    return (userAgent.indexOf('safari') !== -1 && userAgent.indexOf('chrome') === -1);
  }

  /**
   * Focus should only be done on non-mobile devices and
   * NOT on the Safari browser
   */
  shouldFocus() {
    if (this.isMobile()) {
      return false;
    }
    return !this.isSafari();
  }

  /**
   * Determine the mobile operating system. (not looking for Opera)
   * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
   */
  getMobileOperatingSystem(): string {
    const userAgent = navigator.userAgent || navigator.vendor;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'iOS';
    }
    return 'unknown';
  }

  isMobile(): boolean {
    return ( this.getMobileOperatingSystem() !== 'unknown');
  }

  /**
   * redirection with mobile Android fix
   */
  redirect(URL) {
    if (this.isAndroid()) {
      document.location = URL;
    }
    window.location.replace(URL);
  }

  /**
   * redirection with mobile Android fix
   */
  navigateTo(URL) {
    if (this.isAndroid()) {
      document.location = URL;
    }
    document.location.assign(URL);
  }

  getRandomString(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  encryptCodeChallege(codeVerifier: string): string {
    const codeVerifierHash = CryptoJS.SHA256(codeVerifier).toString(CryptoJS.enc.Base64);
    const codeChallenge = codeVerifierHash
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_');

    return codeChallenge;
  }
  // actual oidc call
  callOidc(pingRefId: string, randomCodeVerify: any) {
    const codeVerifier = randomCodeVerify
    const method = 'S256';
    const codeChallenge = this.encryptCodeChallege(codeVerifier);
    const data = {
      response_type: 'code',
      scope: 'openid profile',
      redirect_uri: environment.univ_oidc.redirect_url,
      REF: pingRefId,
      client_id: environment.univ_oidc.client_id,
      code_challenge_method: method,
      code_challenge: codeChallenge
    };

    this.createOidcForm(environment.univ_oidc.url, data);
  }

  createOidcForm(pingRefIdUrl, data) {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = pingRefIdUrl;
    form.target = '_self';
    form.style.display = 'none';

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = data[key];
        form.appendChild(input);
      }
    }
    document.body.appendChild(form);
    form.submit();
  }

  handleNonOUDError(error, appName, enrollUrl) {
    this.spinnerService.hideSpinner();
    console.log(error, appName);
    switch(appName) {
      case 'ESCROW':
      case 'CARBON': {
        if (error === 'OTPLocked' && !enrollUrl) {
          this.alertService.error(this.transmitUtils.getErrorMessage('legacyAccountLocked'));
        } else if (error === 'OTPLocked' && enrollUrl) {
          this.alertService.error(this.transmitUtils.getErrorMessage('enrollLegacyAccountLocked'));
        } else if (error === 'CredentialsDisabled') {
          this.alertService.error(this.transmitUtils.getErrorMessage('nonOUDCredentialDisabled'));
        } else if (error === 'DataNotAvailable') {
          this.alertService.error(universalErrorCodes['nonOudCampaignError']);
        } else if (error === 'CredentialAlreadyLinked' && !enrollUrl) {
          this.alertService.error(universalErrorCodes['addCredsAlreadyLinked']);
        } else if (error === 'CredentialAlreadyLinked' && enrollUrl) {
          this.alertService.error(universalErrorCodes['enrollmentAlreadyLinked']);
        }
        break;
      }
      default: {
        this.alertService.error(this.transmitUtils.getErrorMessage('serviceUnavailable'));
      }
    }
  }

}
