import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../../services/alert.service';
import { StepService } from '../../services/step.service';
import { UniversalTransmitUtils } from '../../utils/universal-transmit-utils';
import { environment } from 'src/environments/environment';
import { StepModel } from '../../enums/step-model';
import { UniversalSessionService } from '../../services/universal-session.service';
import { UniversalAnalyticsService } from '../../services/universal-analytics.service';
import { SsoUtils } from '../../utils/sso-utils';

@Component({
  selector: 'app-identity-registration-otp',
  templateUrl: './identity-registration-otp.component.html',
  styleUrls: ['./identity-registration-otp.component.scss']
})
export class IdentityRegistrationOtpComponent implements OnInit, AfterViewInit {
  @Input() step: StepModel;
  submitted = false;
  loading = false;
  appName = 'achieve';
  passcodeForm: UntypedFormGroup;
  stepIndex;
  backToSelect = false;
  isCancel = false;
  @ViewChild('passcode', { read: ElementRef }) passcode: ElementRef;
  public contactMessage: any = environment.contactMessage;
  public phoneNumber1: string;
  public phoneNumber2: string;
  public targetSelected: string;
  private analyticsSessionId = '';
  private analyticsUserId = '';
  private isAuthenticated = 'n';
  public isAndroid = false;
  applicationName: string;

  public displayOTPErrorMessage = false;
  public displayOTPFailedErrorMessage = false;

  public onSubmitCode: (response: com.ts.mobile.sdk.InputOrControlResponse<any>) => void;
  public resendCode: () => void;
  public changeMode: () => void;
  public onCancel: () => void;
  public universalLoginServiceCase = environment.universalLoginServiceCase;

  constructor(private router: Router, private route: ActivatedRoute,
              private formBuilder: UntypedFormBuilder,
              private stepsService: StepService,
              private alertService: AlertService,
              private utils: SsoUtils,
              private sessionService: UniversalSessionService,
              private analyticsService: UniversalAnalyticsService,
              private transmitUtils: UniversalTransmitUtils) {
    this.createForm();
    this.phoneNumber1 = this.removeChars(this.contactMessage.phone1);
    this.phoneNumber2 = this.removeChars(this.contactMessage.phone2);
    this.isAndroid = this.utils.isAndroid();
  }

  ngOnInit(): void {
    this.stepsService.getCurrentStep().subscribe(step => {
      this.stepIndex = step.stepIndex;
    });
    const data = this.sessionService.getSessionData('identifyRegistrationOtp');
    this.analyticsSessionId = data.analyticsSessionId;
    this.analyticsUserId = data.analyticsUserId;
    this.isAuthenticated = data.isAuthenticated;
    this.applicationName = this.stepsService.applicationName;
  }

  ngAfterViewInit() {
    this.passcode.nativeElement.focus();
    this.resetAnalytics();
  }

  /**
   * remove all characters such as dash and parentheses so that
   * only digits remain
   * @param phoneNumber Full phone number with dashes and more
   */
   removeChars(phoneNumber): string {
    return phoneNumber.replace(/[^0-9]/g, '');
  }

  createForm(){
    this.passcodeForm = this.formBuilder.group({
      passcode: ['', Validators.required]
    });
  }

  get f() {
    return this.passcodeForm.controls;
  }

  // analytics
  resetAnalytics() {
    const analyticsJson = {
      cbpgType: 'enroll',
      cbaction: 'view',
      cbpgFunction: 'verifyidentity',
      cbpgSubFunction: 'enterotp',
      cbpgName: 'view',
      cbaccType: '',
      cbCisKey: this.analyticsUserId,
      cbsessionId: this.analyticsSessionId,
      cbauthenticated: this.isAuthenticated
    };
    this.analyticsService.setAccType('');
    this.analyticsService.updateAnalytics(analyticsJson);
  }

  onSubmit(formData) {
    this.submitted = true;
    this.hideOTPErrors();
    this.alertService.hide();
    if (this.passcodeForm.invalid) {
      return;
    } else {
      const passcode = formData.passcode;
      const input = com.ts.mobile.sdk.OtpInputOtpSubmission.createOtpSubmission(passcode);
      const inputTargetBased = com.ts.mobile.sdk.TargetBasedAuthenticatorInput.createAuthenticatorInput(input);
      const response = com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(inputTargetBased);

      if (this.onSubmitCode) {
        this.onSubmitCode(response);
      }
    }
  }

  public showOTPErrorMessage(){
    console.log('showOTPErrorMessage');
    this.displayOTPErrorMessage = true;
  }

  public showServiceUnavailableError() {
    this.alertService.error(this.transmitUtils.getErrorMessage('serviceUnavailableTryAgain'));
  }

  public showOTPResendFailedErrorMessage() {
    this.displayOTPFailedErrorMessage = true;
  }

  hideOTPErrorMessage() {
    this.displayOTPErrorMessage = false;
  }

  public hideOTPResendFailedErrorMessage() {
    this.displayOTPFailedErrorMessage = false;
  }

  showOTPLockedErrorMessage() {
    this.alertService.error(this.transmitUtils.getErrorMessage('legacyAccountLocked'));
  }

  selectMode() {
    // this.router.navigate(['security']);
    // this.backToSelect = true;
    this.changeMode();
  }

  getCode() {
    this.resendCode();
  }

  back(){
    this.onCancel();
  }

  public getTarget(target) {
    this.targetSelected = target._description;
  }

  public hideOTPErrors() {
    this.displayOTPErrorMessage = false;
    this.displayOTPFailedErrorMessage = false;
  }

}
