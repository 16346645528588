<div *ngIf="!isCancel && !isStep2" class="parent-container d-sm-flex">
    <div class="container">
      <div class="headSection get-started">
        <span> Step {{stepIndex}}b: Enter your existing Credentials </span>
      </div>
      <form [formGroup]="passwordForm" [ngClass]="{ 'was-validated': submitted }" (keyup.enter)="onSubmit(passwordForm.value)"
            (ngSubmit)="onSubmit(passwordForm.value)" novalidate >
        <div class="form">
            <div class="form-row">
                <div class="col-12">
                    <div class="form-label">Next, enter your password.</div>
                </div>
              </div>

              <div class="headSection appName">
                <span [ngClass]="isCFF ? '' : 'registered'">{{displayName}}</span>
              </div>
          <div class="form-row" [ngClass]="{ 'has-error': f.submitted  && f.password.errors }">
            <div class="col-xl-4 col-md-5 col-sm-7">
              <label for="password">Password</label>
              <div class="input-group password">
                <input type="password" class="form-control" formControlName="input_password" maxlength="24" autocomplete="off"
                       id="input_password" name="input_password" tabindex="4" #input_password appPasswordtoggle required >
                <div class="invalid-feedback" id="userid-error-msg">
                  <div class="error-message">
                    Password is required
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="error-message" *ngIf="displayErrorMessage" >The Password you entered does not match our records.
              <span>Please check it and try again.</span>
            </div> -->
          </div>
          <div class="buttonArea">
            <button class="btn btn-primary cbensightenevent" name="custom-password-authenticator-continue-button" id="custom-password-authenticator-continue-button" type="submit"
                    tabindex="6" [disabled]="loading" cbdata-type="button" cbdata-reason="add-enter-password-continue" value="Continue" aria-label="Continue"> Continue </button>
            <button type="button" tabindex="7" class="btn btn-secondary cbensightenevent" id="custom-password-authenticator-cancel-button"
                    (click)="onClickCancel()" cbdata-type="button" cbdata-reason="add-enter-password-cancel" aria-label="Cancel">Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </div>

<!-- <app-ac-select-application *ngIf="isCancel"></app-ac-select-application>
<app-ac-select-otp *ngIf="isStep2"></app-ac-select-otp> -->
