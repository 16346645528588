import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { baseURL, environment } from '../../../environments/environment';
import jwt_decode from 'jwt-decode';
import { PlatformLocation } from '@angular/common';
import { datadogLogs } from '@datadog/browser-logs';
import { AlertService } from '../../core/services/alert.service';
import { UniversalSessionService } from '../../core/services/universal-session.service';
import { TransmitUniversalService } from '../../core/services/transmit-universal.service';
import { ThreatMetrixUniversalService } from '../../core/services/threat-metrix-universal.service';
import { SpinnerService } from '../../core/services/spinner.service';
import { UniversalAnalyticsService } from '../../core/services/universal-analytics.service';
import { UniversalTransmitUtils } from '../../core/utils/universal-transmit-utils';
import { SsoUtils } from '../../core/utils/sso-utils';
import { ApplicationService } from '../../core/services/application.service';
import { StepService } from '../../core/services/step.service';
import { universalErrorCodes } from '../../core/errorConfig/universal-error-codes.config';
import { UniversalApiCallsService } from '../../core/services/universal-api-calls.service';
import { Const } from '../../core/enums/const.enum';
import { assetURL } from 'src/environments/environment';
import { sharedState, alertState } from '@citizens/mfe-shared-state';
import {
  NgxSsoAlertService,
  NgxSsoApplicationService,
  NgxSsoSpinnerService,
  NgxSsoStepService,
  NgxSsoUniversalAnalyticsService,
  NgxSsoUniversalApiCallsService,
  NgxSsoUniversalSessionService,
  NgxSsoUniversalThreatMatrixService,
  NgxSsoUniversalTransmitService,
  NgxSsoUniversalTransmitUtils,
  NgxSsoUtils,
} from '@citizens-digital/ngx-sso-lib/ngx-sso-lib';
import { timer } from 'rxjs';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit {
  public submitted = false;
  public loading = false;
  public loginForm: UntypedFormGroup;
  public authError = false;
  public hide = true;
  public displayErrorMessage = false;
  public hiddenType: boolean;
  public isMobile = false;
  public display = true; // show/hide the component
  public isSuccess = false;
  public response: any;
  public fraudProtectionUrl = '';
  public displayFraudBanner = true;
  @ViewChild('viewContainerRef', { static: false, read: ViewContainerRef })
  viewContainerRef: ViewContainerRef;
  @ViewChild('universalId') firstField: ElementRef;
  universalId: string;
  private inputPass: string;
  private pageName = 'login';
  public isRemembered = false;
  private tmxUnivSessionId: string;
  private profiledUrl: string;
  private analyticsSessionId = '';
  private isAuthenticated = 'n';
  public butlerApp = false;
  contact_url = environment.mobile_login.contact_url;
  private relayStateLimit = 1024;
  loginHeader = environment.loginHeader;
  loginDesc = environment.loginDesc;
  showEnrollNow = environment.loginShowEnrollNow;
  private targetWindow: Window| null = null;
  private rootAssetUrl = window.location.origin;
  private timer: any;
  private alive = environment.univ_alive;
  private inactiveInterval = this.alive.inactiveInterval;  // defaults to 15 second
  private aliveInterval = this.alive.idleTimeout;        // defaults to 20 minutes
  


  constructor(
    private router: Router,
    private readonly formBuilder: UntypedFormBuilder,
    private utils: NgxSsoUtils,
    private ssoUtils: SsoUtils,
    private sessionService: NgxSsoUniversalSessionService,
    private transmitService: TransmitUniversalService,
    private threatMetrixUnivService: NgxSsoUniversalThreatMatrixService,
    private alertService: NgxSsoAlertService,
    private spinnerService: NgxSsoSpinnerService,
    private analyticsService: NgxSsoUniversalAnalyticsService,
    private transmitUtils: UniversalTransmitUtils,
    private appService: ApplicationService,
    private stepService: NgxSsoStepService,
    private platformLocation: PlatformLocation,
    private idle: Idle,
    private keepalive: Keepalive,
    private cdRef: ChangeDetectorRef,
    private universalApiCallsService: NgxSsoUniversalApiCallsService
  ) {
    
    this.idle.setIdle(this.inactiveInterval);
    this.idle.setTimeout(this.aliveInterval);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(()=>{
      this.cdRef.detectChanges()
    })

    idle.onTimeout.subscribe(() => {
      this.loadStargatePortal();
    });

    sharedState.setFlagBS('login', true);
    this.createForm();
    platformLocation.onPopState(() => (this.stepService.onBackButton = true));
  }

  get f() {
    return this.loginForm.controls;
  }

  get assetURL() {
    return assetURL;
  }

  ngOnInit() {
    this.loadStargatePortal();

    sharedState.getFlagBS('login').subscribe((response) => {
      console.log('Inside getFLag Login', response);
    });
    
    this.fraudProtectionUrl = Const.fraudProtectionUrl;

    this.tmxUnivSessionId = this.loadTmxUnivLoginServ();
    const data = this.sessionService.getSessionData(this.pageName, false);
    this.universalId = data.universalId;
    this.analyticsSessionId = data.analyticsSessionId;

    this.isRemembered = data.isRemembered;
    if (data.isRemembered) {
      if (!!this.universalId) {
        this.loginForm.patchValue({ universalId: this.universalId });
      }
      this.loginForm.patchValue({ remember: 'checked' });
    }

    this.spinnerService.clearText();
    if (this.transmitService.getTransmitSessionExpired()) {
      alertState.error('Session expired. Please try again.');
      this.transmitService.setTransmitSessionExpired(false);
    }

    /* const error = this.sessionService.ssoValidation$;
    if(error) {
      error.subscribe((err) => {
        if(err && err.includes('credentials'))
        this.alertService.error(err);
      });
    } */

    const error = this.sessionService.getSSOTokenStatus();
    if (error) {
      datadogLogs.logger.error('Service Unavailable -- Login', {
        error: error,
      });
      alertState.error(
        this.transmitUtils.getServiceUnavailableErrorMessage()
      );
    }
  }

  loadStargatePortal() {
    this.idle.watch();
    const iFrame = document.createElement('iframe')
    iFrame.id = 'stargatePortal';
    iFrame.style.display = 'none';

    (<HTMLIFrameElement>iFrame).src = baseURL;
    document.body.appendChild(iFrame);
  }

  ngAfterViewInit(): void {
    if (this.utils.shouldFocus()) {
      this.firstField.nativeElement.focus();
    }
    this.sessionService.setPreviousPageName(this.pageName);
    if (!this.analyticsService.isInitialized()) {
      this.analyticsService.init(this.analyticsSessionId, '', '');
    } else {
      this.resetAnalytics();
    }

    this.loginForm.controls['universalId'].valueChanges.subscribe((data) => {
      this.stepService.universalId = data;
    });
    if (this.isMobile) {
      this.loginForm.patchValue({ isMobile: true });
    }
    this.transmitService.initializeTransmit(
      environment.transmitUrl,
      this.viewContainerRef,
      this,
      environment.univ_portal_transmitJourney
    );
  }

  closeBanner() {
    this.displayFraudBanner = false;
  }

  createForm() {
    this.loginForm = this.formBuilder.group({
      universalId: ['', Validators.required],
      inputPass: ['', Validators.required],
      isMobile: [false],
      remember: [''],
    });
  }

  setComponentDisplay(val: boolean) {
    this.display = val;
    if (val) {
      this.resetAnalytics();
    }
  }

  resetAnalytics() {
    const analyticsJson = {
      cbpgType: 'login',
      cbaction: 'start',
      cbpgFunction: 'login',
      cbpgSubFunction: '',
      cbpgName: 'start',
      cbsessionId: this.analyticsSessionId,
      cbaccType: '',
      cbCisKey: '',
      cbauthenticated: this.isAuthenticated,
    };
    this.analyticsService.setAccType('');
    this.analyticsService.updateAnalytics(analyticsJson);
  }

  /**
   * If the checkbox is checked/true cookies will be saved
   * if unchecked cookies will be destroyed.
   * @param check event target determines whether the 'remember me' checkbox is checked or not
   */
  rememberCheck(check) {
    const checked = check.checked;
    this.setRemembered(checked, false);
  }

  /**
   * If the checkbox is checked/true cookies will be saved
   * if unchecked cookies will be destroyed.
   * @param check boolean true/false
   */
  setRemembered(isChecked: boolean, isSubmit: boolean) {
    const universalId = this.f.universalId.value;
    this.sessionService.setUserInfo(universalId, isChecked);
    if (!isChecked) {
      this.sessionService.deleteRememberedCookies();
      // now that the cookies are deleted remove the info from the form but not on a submit
      if (!isSubmit) {
        this.universalId = '';
        this.loginForm.patchValue({ universalId: '' });
      }
    }
  }

  onSubmit(formData) {
    this.displayFraudBanner = false;
    this.sessionService.setAuthenticated(false);
    this.submitted = true;
    alertState.hide();
    if (this.loginForm.invalid) {
      return;
    } else {
      this.spinnerService.clearText();
      this.spinnerService.setText('Verifying...');
      const remember = formData.remember;
      const universalId = formData.universalId.toLowerCase();
      const inputPass = formData.inputPass;
      sessionStorage.setItem('universalId', universalId);
      this.setRemembered(remember, true);

      this.profiledUrl =
        environment.tmxProfiledUrlPrefix + '/sso/uportal/login';

      // relay state check on login
      let relayState = sessionStorage.getItem('relayState');
      if (relayState) {
        if (relayState.length > this.relayStateLimit) {
          var userAgent = navigator.userAgent || navigator.vendor;
          this.universalApiCallsService
            .sendRelayStateEmail(relayState, universalId, userAgent)
            .subscribe(
              (res) => {
                console.log(relayState, res, 'relay state too long');
              },
              (err) => {
                console.error(err);
              }
            );
        }
      }

      const codeVerifier = this.ssoUtils.getRandomString(128)
      sessionStorage.setItem('code_verifier', codeVerifier);

      this.passCrossDomainData()
      // this.appService.postCrossDomainData(dataToSend, domainUrl, 'stargatePortal')

      this.transmitService
        .authenticate(
          undefined,
          undefined,
          undefined,
          universalId,
          inputPass,
          undefined,
          'login',
          this.tmxUnivSessionId,
          this.profiledUrl,
          '',
          '',
          ''
        )
        .then((res) => {
          const tokenInfo = this.getDecodedAccessToken(res._token);
          const pingLoginUrl = tokenInfo['ping_login_url'];
          const ping_sso_url = tokenInfo['ping_sso_url'];
          const universalId2 = tokenInfo['sub'];
          const pingRefId = tokenInfo['ping_ref_id'];

          // if (tokenInfo.smbUser && (ping_sso_url !== undefined || ping_sso_url !== null || ping_sso_url !== '')) {
          //   window.location.href = ping_sso_url
          // }
          // else {
          this.sessionService.setUniversal(universalId2);
          this.ssoUtils.callOidc(pingRefId, codeVerifier);
          sessionStorage.setItem('cardUserLogin','true')
          localStorage.setItem('pingRefId', pingRefId)
          this.passCrossDomainData()
          // }

          // if (pingLoginUrl !== undefined || pingLoginUrl !== null || pingLoginUrl !== '') {
          //   window.location.href = pingLoginUrl;
          // } else {

          // }
        })
        .catch((error) => {
          if (this.sessionService.getCancelSelection()) {
            this.sessionService.setCancelSelection(false);
            this.sessionService
              .isPrmMobileApp()
              .then((isMobile) => {
                const loginUrl = isMobile
                  ? '/uportal/mobile-login'
                  : '/uportal/login';
                if (loginUrl === this.router.url) {
                  location.reload();
                } else {
                  this.router
                    .navigateByUrl('/', { replaceUrl: true })
                    .then(() => {
                      this.router.navigate([loginUrl]);
                    })
                    .catch((error) => {
                      console.error('Error navigating:', error);
                    });
                }
              })
              .catch((error) => {
                console.error('Error checking mobile app status:', error);
              });
          } else {
            this.handleError(error);
          }
        });
    }
  }

  handleError(error) {
    console.error('error', error);
    datadogLogs.logger.error('error -- login journey', { error: error });
    // when user cancels the request (errorcode - 7), error display is ignored
    if (error._errorCode !== 7) {
      if (this.transmitUtils.isSessionExpired(error)) {
        // this.transmitService.setTransmitSessionExpired(true);
        this.cancelUrlRedirect();
      } else if (this.transmitUtils.getErrorCode(error) === '3203') {
        // error code 3203 - TMX hard reject
        datadogLogs.logger.error('TMX reject -- login journey', {
          error: error,
        });
        this.router.navigate(['uportal/rejection']);
      } else if (
        this.getNested(error, '_data', 'additional_data', 'locked') == true &&
        this.getNested(error, '_data', 'additional_data', 'type') == 'user-ldap'
      ) {
        alertState.error(
          this.transmitUtils.getPasswordLockedErrorMessage()
        );
      } else if (
        this.getNested(error, '_data', 'additional_data', 'locked') == true
      ) {
        alertState.error(this.transmitUtils.getOTPLockedErrorMessage());
      } else if (
        this.getNested(error, '_message') ==
        'Authentication resulted in failure'
      ) {
        alertState.error(
          this.transmitUtils.getErrorMessage('WrongCredentials')
        );
      } else if (this.getNested(error, '_data', 'status') == 428) {
        alertState.error(
          this.transmitUtils.getErrorMessage('akamaiError1')
        );
      } else if (
        this.getNested(error, '_message') &&
        this.getNested(error, '_message').includes(
          "You don't have permission to access"
        )
      ) {
        let message = this.getNested(error, '_message');
        let referenceToEndOfString = message
          ? message.substring(message.indexOf('Reference'))
          : undefined;
        let justReference = referenceToEndOfString
          ? referenceToEndOfString.substring(
              0,
              referenceToEndOfString.indexOf('</HTML>') - 8
            )
          : undefined;
        this.displayAkamaiErrorWithReference(
          justReference ? justReference.trim() : undefined
        );
      } else if (
        this.transmitUtils.serviceUnavailableErrorCodes.indexOf(
          this.transmitUtils.getErrorCode(error)
        ) !== -1
      ) {
        datadogLogs.logger.error('Transmit is unavilable -- login', {
          error: error.code,
        });
        this.sessionService.setErrorDescription({
          description: 'Transmit is unavailable',
          code: error.code,
        });
        this.router.navigate(['unavailable']);
      } else if (
        this.getNested(
          error,
          'data',
          'failure_data',
          'reason',
          'data',
          'error',
          'numcode'
        ) === '3252'
      ) {
        datadogLogs.logger.error('Ping service unavailable -- login journey', {
          error: error,
        });
        alertState.error(this.transmitUtils.getErrorMessage('3252'));
      } else {
        const errorMessage = this.transmitUtils.geTransmitErrorMessage(error);
        if (errorMessage) {
          datadogLogs.logger.error('Tranmsit Error -- login journey', {
            error: error,
          });
          alertState.error(errorMessage);
        } else {
          this.router.navigate(['unavailable']);
        }
      }
    }
  }

  passCrossDomainData() {
    const domainUrl = baseURL
    const dataToSend = {
      localStorageData: { ...localStorage },
      sessionStorageData: { ...sessionStorage },

    }
    this.appService.postCrossDomainData(dataToSend, domainUrl, 'stargatePortal')
  }

  trouble() {
    console.log('Inside trouble');
    window.open(environment.troubleLoginUrl, '_self');
  }

  onCancel(evt) {
    evt.preventDefault();
    this.cancelUrlRedirect();
  }

  cancelUrlRedirect() {
    // update the rememberMe info
    const remember = this.f.remember.value;
    this.setRemembered(remember, false);

    const URL = this.sessionService.getCancelUrl();
    this.utils.redirect(URL);
  }

  logout() {
    // update the rememberMe info
    const remember = this.f.remember.value;
    this.setRemembered(remember, false);
    sessionStorage.removeItem('isSMBUser');
    this.router.navigate(['uportal/logout/universal']);
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  loadTmxUnivLoginServ(): string {
    const tmxUnivSessId =
      this.threatMetrixUnivService.initThreatMetrixService('Universal');
    this.threatMetrixUnivService.addEnhancedProfilingScriptTag(tmxUnivSessId);
    return tmxUnivSessId;
  }

  getNested(obj, ...args) {
    return args.reduce((obj, level) => obj && obj[level], obj);
  }

  displayAkamaiErrorWithReference(reference) {
    let akamaiErrorMessage = this.transmitUtils.getErrorMessage('akamaiError2');
    if (reference) {
      akamaiErrorMessage = akamaiErrorMessage + ' (' + reference + ')';
    }
    alertState.error(akamaiErrorMessage);
  }
}
