import { Component } from '@angular/core';
import { NgxSsoInitLibService } from '@citizens-digital/ngx-sso-lib/ngx-sso-lib';
import { assetURL, environment } from 'src/environments/environment';
import { singleSpaPropsSubject } from 'src/single-spa/single-spa-props';

@Component({
  selector: 'app-login-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(private initLib: NgxSsoInitLibService) {
    this.initLib.setEnvData(environment);
    this.initLib.setEnvName("dev");
    this.initLib.setAssetURL(assetURL)
  }

  ngOnInit() {
    console.log("P1SIT checkcechk",)
    sessionStorage.clear()
    localStorage.clear()
    singleSpaPropsSubject.subscribe((props: any) => {
      console.log(props);
    });
  }

}
