<div class="parent-container" #viewContainerRef>
  <!-- Login Area -->
    <div class="parent-container d-sm-flex">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-4 tablet-5">
            <div class="login-section">
              <div class="headSection secure">{{ loginHeader }}</div>
              <p class="header-text-context" *ngIf="!butlerApp">{{ loginDesc }}</p>
              <form [formGroup]="loginForm" [ngClass]="{ 'was-validated': submitted }"
                (ngSubmit)="onSubmit(loginForm.value)" novalidate>
                <div class="form">
                  <div class="form-row" [ngClass]="{ 'has-error': f.submitted  && f.userId.errors }">
                    <div class="col-12">
                      <label for="universalId">Universal ID</label>
                      <div class="input-group universalId">
                        <input type="text" class="form-control" formControlName="universalId" id="universalId"
                          name="universalId" maxlength="24" autocomplete="off" tabindex="2" #universalId required
                          appTrimInput>
                        <div class="invalid-feedback" id="universalid-error-msg">
                          <div class="error-message" id="login-form">Universal ID is required</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-row form-check toggle-switch" *ngIf="butlerApp">
                  <label class="switch">
                    <input type="checkbox" id="remember" name="remember" #remember tabindex="4" formControlName="remember"
                      value="true" (change)="rememberCheck($event.target)">
                    <span class="slider round"></span>
                  </label>
                  <label class="label-remember">Remember this Universal ID</label>
                </div>
                <div class="form-row" [ngClass]="{ 'has-error': f.submitted  && f.password.errors }">
                  <div class="col-12">
                    <label for="inputPass">Password</label>
                    <div class="input-group password">
                      <input [type]="hiddenType ? 'text' : 'password'" class="form-control" formControlName="inputPass"
                        maxlength="24" id="inputPass" name="inputPass" tabindex="3" #inputPass required appPasswordtoggle>
                      <div class="invalid-feedback">
                        <div class="error-message">Password is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="error-message" *ngIf="displayErrorMessage">The Password you entered does not match our
                    records.
                    <span>Please check it and try again.</span>
                  </div>
                </div>

                <div class="form-row form-check" *ngIf="!butlerApp">
                  <div class="custom-control custom-checkbox col-12">
                    <input type="checkbox" class="custom-control-input" id="remember" name="remember" #remember
                      tabindex="4" formControlName="remember" value="true" (change)="rememberCheck($event.target)">
                    <label class="custom-control-label remember-checkbox-text" for="remember">Remember this Universal ID</label>
                    <span class="sso-tooltip">
                      <svg class="sso-alert__questionIcon" focusable="false">
                        <use [attr.href]="rootAssetUrl+'/assets/icons/cbds-icons-ui.svg#question-circle'"
                          aria-describedby="question"
                          [attr.xlink:href]="rootAssetUrl+'/assets/icons/cbds-icons-ui.svg#question-circle'"></use>
                      </svg>
                      <span id="question" class="tooltipText loginTooltip">
                        <span class="hoverText">Checking this box will
                          eliminate the need to re-enter
                          your Universal ID every time you log in.
                          For your protection, DO NOT check this box if
                          you're using a public device accessible to others.
                          <span id="tail">
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                </div>

                <input type="hidden" class="form-control" formControlName="isMobile" id="isMobile" name="isMobile"
                  #isMobile>

                <div class="buttonArea">
                  <button class="btn btn-primary cbensightenevent login-button" name="btnLogin" id="btnLogin" #btnLogin type="submit"
                    tabindex="4" cbdata-type="button" cbdata-reason="secure-login-login" [disabled]="loading"
                    value="Login" aria-label="Next">
                    <span class="login-button-lock"></span>Log In
                  </button>
                </div>
              </form>
              <div class="container trouble-login" *ngIf="!butlerApp">
                <div class="row">
                  <div id="troubleLoggingIn" class="col-12">
                    <button type="button" class="btn btn-link cbensightenevent btn-trouble" tabindex="6" cbdata-type="link"
                      cbdata-reason="secure-login-trouble" (click)="trouble()">Trouble Logging In?
                    </button>
                  </div>
                  <div id="enrollNow" class="col-12" *ngIf="showEnrollNow">
                    Need a Universal Id?
                    <button type="button" class="btn btn-link cbensightenevent" tabindex="6" cbdata-type="link"
                      cbdata-reason="secure-login-trouble" (click)="enroll()">Enroll now</button>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="login-section" *ngIf="!butlerApp">
              <div class="personal">
                <p class="personal-text">Are you a Personal/Small Business Client?</p>
                <a href="{{olbUrl}}" target="_blank">Login to Online Banking</a>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- mobile butler app section -->
    <div class="container" *ngIf="butlerApp">
      <div class="row">
        <div class="text-center mt-3">
          <div id="troubleLoggingIn">
            <button type="button" class="btn btn-link cbensightenevent" tabindex="6" cbdata-type="link"
              cbdata-reason="secure-login-trouble" (click)="trouble()">Set New Password / Trouble Logging In
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- mobile butler app section ends -->
  </div>

<ngx-sso-app-modal-spinner></ngx-sso-app-modal-spinner>
