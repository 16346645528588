import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../services/alert.service';
import { ApplicationService } from '../../services/application.service';
import { SpinnerService } from '../../services/spinner.service';
import { StepService } from '../../services/step.service';
import { UniversalTransmitUtils } from '../../utils/universal-transmit-utils';
import { universalErrorCodes } from '../../errorConfig/universal-error-codes.config';
import { UniversalSessionService } from '../../services/universal-session.service';
import { UniversalAnalyticsService } from '../../services/universal-analytics.service';

@Component({
  selector: 'app-ac-enter-password',
  templateUrl: './ac-enter-password.component.html',
  styleUrls: ['./ac-enter-password.component.scss']
})
export class AcEnterPasswordComponent implements OnInit, AfterViewInit {
  stepIndex;
  applicationName: string;
  passwordForm: UntypedFormGroup
  submitted = false;
  isStep2 = false;
  loading = false;
  isCancel = false;
  displayName: string;
  public onSubmitPassword: (response: com.ts.mobile.sdk.InputOrControlResponse<any>) => void;
  public onCancel: () => void;
  public isCFF = false;
  private pageName = 'addCredential';
  private customerId: string;
  private analyticsSessionId = '';
  private analyticsUserId = '';
  private isAuthenticated = 'n';
  @ViewChild('input_password', {read: ElementRef}) input_password: ElementRef;

  constructor(private stepsService: StepService,
              private spinnerService: SpinnerService,
              private readonly formBuilder: UntypedFormBuilder,
              private alertService: AlertService,
              private applicationService: ApplicationService,
              private transmitUtils: UniversalTransmitUtils,
              private sessionService: UniversalSessionService,
              private analyticsService: UniversalAnalyticsService) {
      this.createForm();
    }

  ngOnInit(): void {
    this.stepsService.getCurrentStep().subscribe(step => {
      this.stepIndex = step.stepIndex;
    });
    this.applicationName = this.stepsService.applicationName;
    if (this.applicationName === 'CCF') {
      this.isCFF = true;
    }
    this.displayName = this.applicationService.getDisplayName(this.applicationName);

    // Initialize analytics
    const data = this.sessionService.getSessionData(this.pageName);
    this.customerId = data.customerId;
    this.analyticsSessionId = data.analyticsSessionId;
    this.analyticsUserId = data.analyticsUserId;
    this.isAuthenticated = data.isAuthenticated;
  }

  ngAfterViewInit(): void {
    this.input_password.nativeElement.focus();
    if (!this.analyticsService.isInitialized()) {
      this.analyticsService.init(this.analyticsSessionId, this.analyticsUserId, '');
    } else {
      this.resetAnalytics();
    }
  }

  createForm(){
    this.passwordForm = this.formBuilder.group({
      input_password: ['', Validators.required]
    });
  }

  get f() {
    return this.passwordForm.controls;
  }

  onSubmit(formData){
    this.submitted = true;
    this.alertService.hide();

    if (this.passwordForm.invalid) {
      return;
    } else {
      const passText = formData.input_password;
      const inputResponse = com.ts.mobile.sdk.PasswordInput.create(passText);
      const response = com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(inputResponse);

      if (this.onSubmitPassword) {
        this.onSubmitPassword(response);
      }
      // this.isStep2 = true;
    }
  }

  showErrorMessage(error?){
    if (error && universalErrorCodes[error]) {
      this.alertService.error(this.transmitUtils.getErrorMessage(error));
    } else {
      this.alertService.error(this.transmitUtils.getServiceUnavailableErrorMessage());
    }
  }

  displayAkamaiErrorWithReference(reference) {
    let akamaiErrorMessage = this.transmitUtils.getErrorMessage('akamaiError2');
    if (reference) {
      akamaiErrorMessage = akamaiErrorMessage + ' (' + reference + ')';
    }
    this.alertService.error(akamaiErrorMessage);
  }

  onClickCancel() {
    // this.isCancel = true;
    // actually calling method in password-authenticator-service
    if (this.onCancel) {
      this.onCancel();
    }
  }

  showPasswordLockedErrorMessage() {
    this.alertService.error(this.transmitUtils.getErrorMessage('legacyAccountLocked'));
  }

  resetAnalytics() {
    const analyticsJson = {
      cbpgType: 'addcredential',
      cbaction: 'view',
      cbpgFunction: 'verifyidentity',
      cbpgSubFunction: 'password',
      cbpgName: 'view',
      cbsessionId: this.analyticsSessionId,
      cbaccType: '',
      cbCisKey: this.analyticsUserId,
      cbauthenticated: this.isAuthenticated
    };
    this.analyticsService.setAccType('');
    this.analyticsService.updateAnalytics(analyticsJson);
  }

}
