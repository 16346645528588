import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SsoUtils } from './core/utils/sso-utils';
import { RouterModule } from '@angular/router';
import { UniversalTransmitUtils } from './core/utils/universal-transmit-utils';
import { UniversalTransmitUiHandler } from './core/universal/universal-transmit-ui-handler';
import { IdentityRegistrationPasswordComponent } from './core/components/identity-registration-password/identity-registration-password.component';
import { AcEnterPasswordComponent } from './core/components/ac-enter-password/ac-enter-password.component';
import { IdentityRegistrationSelectionComponent } from './core/components/identity-registration-selection/identity-registration-selection.component';
import { IdentityRegistrationOtpComponent } from './core/components/identity-registration-otp/identity-registration-otp.component';
import { OtpSelectionComponent } from './core/components/otp-selection/otp-selection.component';
import { PasswordComponent } from './core/components/password/password.component';
import { EnterOtpComponent } from './core/components/enter-otp/enter-otp.component';
import { AcSelectOtpComponent } from './core/components/ac-select-otp/ac-select-otp.component';
import { AcEnterOtpComponent } from './core/components/ac-enter-otp/ac-enter-otp.component';
import { CreateUniversalLoginProfileComponent } from './core/components/create-universal-login-profile/create-universal-login-profile.component';
import { ResetPasswordComponent } from './core/components/reset-password/reset-password.component';
import { TemporaryPasswordComponent } from './core/components/temporary-password/temporary-password.component';
import { MvpConfirmationComponent } from './core/components/confirmation/confirmation.component';
import { APP_BASE_HREF } from '@angular/common';
import { PasswordtoggleDirective } from './core/utils/passwordtoggle.directive';
import { NgxSsoModule } from '@citizens-digital/ngx-sso-lib/ngx-sso-lib';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    IdentityRegistrationPasswordComponent,
    AcEnterPasswordComponent,
    IdentityRegistrationSelectionComponent,
    IdentityRegistrationOtpComponent,
    OtpSelectionComponent,
    PasswordComponent,
    EnterOtpComponent,
    AcSelectOtpComponent,
    AcEnterOtpComponent,
    CreateUniversalLoginProfileComponent,
    ResetPasswordComponent,
    TemporaryPasswordComponent,
    MvpConfirmationComponent,
    PasswordtoggleDirective
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    NgxSsoModule,
    NgIdleKeepaliveModule.forRoot()
  ],
  providers: [
    SsoUtils,
    UniversalTransmitUtils,
    UniversalTransmitUiHandler,
    {
      provide: APP_BASE_HREF,
      useValue: "/"
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
