import { ComponentFactoryResolver, ComponentRef, Renderer2, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { UniversalSessionService } from '../services/universal-session.service';
import { AppInjectorService } from '../services/app-injector.service';
import { SpinnerService } from '../services/spinner.service';
import { TemporaryPasswordComponent } from '../components/temporary-password/temporary-password.component';
import { NgxSsoSpinnerService } from '@citizens-digital/ngx-sso-lib/ngx-sso-lib';

class UniversalTemporaryPasswordFormSession implements com.ts.mobile.sdk.UIFormSession {
  // @ViewChild('forgotPasswordForm', { static: false }) forgotPasswordForm;

  resolver: ComponentFactoryResolver;
  viewContainerRef: ViewContainerRef;
  clientContext: object | null;
  actionContext: com.ts.mobile.sdk.PolicyAction | null;
  uiContext: com.ts.mobile.sdk.UIContext;
  mode: com.ts.mobile.sdk.AuthenticatorSessionMode;
  router: Router;
  changeTempPwRef: ComponentRef<TemporaryPasswordComponent>;
  formId: string;
  payload: string;

  // mode: com.ts.mobile.sdk.AuthenticatorSessionMode;
  currentHandler: (response: com.ts.mobile.sdk.FormInput) => void;
  private renderer: Renderer2;
  // data: any = {};
  private loginComp: any;

  constructor(formId: string,
              payload: object,
              private spinnerService: NgxSsoSpinnerService,
              private route: Router,
              private sessionService: UniversalSessionService) {
    this.router = route;
    this.resolver = AppInjectorService.injector.get(ComponentFactoryResolver);
    this.formId = formId;
    // this.payload = payload;
  }

  startSession = (clientContext: object | null, actionContext: com.ts.mobile.sdk.PolicyAction | null): void => {
    this.clientContext = clientContext;
    this.actionContext = actionContext;
    //  this.mode = mode;

    this.viewContainerRef = (clientContext as any).viewContainerRef;
    this.renderer = (clientContext as any).renderer;

    if (clientContext) {
      this.loginComp = clientContext["loginComp"];
    }
    this.loginComp.setComponentDisplay(false);
    this.renderChangePwComponent();
  }

  renderChangePwComponent() {
    this.viewContainerRef.clear();

    const componentFactory = this.resolver.resolveComponentFactory(TemporaryPasswordComponent);
    this.changeTempPwRef = this.viewContainerRef.createComponent(componentFactory);

    this.changeTempPwRef.instance.onSubmitPassword = this.onResetPwd;
    this.changeTempPwRef.instance.onCancelUniversalTmpPw = this.onCancelUniversalTmpPw;
  }

  promiseFormInput = (): Promise<com.ts.mobile.sdk.FormInput> => {
    return new Promise((resolve, reject) => {
      this.currentHandler = (response: com.ts.mobile.sdk.FormInput) => {
        resolve(response);
      };
    });
  }
  changeSessionModeToRegistrationAfterExpiration = (): void => {
  }

  promiseRecoveryForError(error: any, validRecoveries: any, recovery: any) {
    return new Promise((resolve, reject) => {
      resolve(com.ts.mobile.sdk.AuthenticationErrorRecovery.Fail);
    });
  }

  public onCancelUniversalTmpPw = () => {
    this.sessionService.setCancelSelection(true);
    this.currentHandler(com.ts.mobile.sdk.FormInput.createFormCancellationRequest());
  }

  onContinue = (payload) => {
    this.changeTempPwRef.instance.showTransmitErrorMessage(payload);
  }

  onError = (payload) => {
    console.error('TempPasswordForm onError=', payload);
    this.changeTempPwRef.instance.showTransmitErrorMessage(payload);
  }

  endSession = () => {
  }

  private onResetPwd = (newPwd: string) => {
    this.spinnerService.setText('');
    const params = {
      // current_password_input: oldPwd,
      password_input: newPwd,
      password_confirm_input: newPwd
    };
    const response = com.ts.mobile.sdk.FormInput.createFormInputSubmissionRequest(params);
    this.currentHandler(response);
  }
}

export default UniversalTemporaryPasswordFormSession;
