import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { UniversalSessionService } from '../../core/services/universal-session.service';

@Injectable({
  providedIn: 'root'
})
export class UniversalApiCallsService {

  // config
  public appDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public appData$: Observable<any> = this.appDataSubject.asObservable();

  public configData: any;

  constructor(private https: HttpClient, private universalSession: UniversalSessionService) { }

  public removeCreds(universalId, legacy_uid): Observable<any> {

    const url = environment.univ_removeCreds;

    const body = {
      "universalId": universalId,
      "legacyId": legacy_uid
    };

    return this.https.post(url, body);
  }

  public saveUserProfile() {
    const url = environment.univ_saveCreds;
    return this.https.post<any>(url, this.universalSession.enrollApiBody);
  }

  public saveNonOudUserProfile() {
    const url = environment.univ_nonOudSaveCreds + this.universalSession.getUniversal();
    return this.https.post<any>(url, this.universalSession.enrollNonOudApiBody);
  }

  public deleteCreds(universalId) {
    const url = environment.univ_deleteUserProfile + `/${universalId}`;
    return this.https.post<any>(url, {});
  }

  // for butler
  public getContactId(universalId: string, persistentId: string) {
    const body = {
      "universalId": universalId,
      "persistentId": persistentId,
      "applicationName": "Digital Butler"
    }

    const url = environment.univ_getButlerContactID;
    return this.https.post<any>(url, body);
  }

  // config 
  getAppsData(): Observable<any[]> {
    const url = environment.univ_apps_url;
    return this.https.get<any[]>(url);
  }

  updateSelectedAppData(selectedAppName: any): void {
    this.getAppsData().subscribe((data: any[]) => {
      const selectedAppData = data.find((val: any) => val.name === selectedAppName);
      if (selectedAppData) {
        this.appDataSubject.next(selectedAppData);
        this.configData = selectedAppData;
      }
    })
  }

  // relay state mail 
  sendRelayStateEmail(relayState: string, universalId: string, userAgent: any) {
    const body = {
      "universalId" : universalId,
      "relayState" : relayState,
      "userAgent" : userAgent,
      "mail": environment.univ_relayStateEmailID
    }
    const url = environment.univ_relayStateEmail;
    return this.https.post<any>(url, body);
  }

  // user admin check 
  checkAdmin(legacy_id: string) {
    const url = environment.univ_getAdminData + '/' + legacy_id;
    // const headers = new HttpHeaders({'Authorization': environment.univ_tokenBlob})
    // return this.https.get<any>(url, { headers });
    return this.https.get<any>(url);
  }
}