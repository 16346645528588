import { Router } from '@angular/router';
import { SpinnerService } from '../services/spinner.service';
import { AlertService } from '../services/alert.service';
import { universalErrorCodes } from '../errorConfig/universal-error-codes.config';
import { UniversalTransmitUtils } from '../utils/universal-transmit-utils';
import { NgxSsoSpinnerService } from '@citizens-digital/ngx-sso-lib/ngx-sso-lib';


export class UniversalPasswordAutopostAuthenticatorService implements com.ts.mobile.sdk.UIAuthenticatorSession<com.ts.mobile.sdk.PasswordInput> {

  username: string;
  clientContext: any;

  constructor(title: string,
              username: string,
              private route: Router,
              private spinnerService: NgxSsoSpinnerService,
              private alertService: AlertService,
              private transmitUtils: UniversalTransmitUtils) {
    this.username = username;
  }

  startSession(description: com.ts.mobile.sdk.AuthenticatorDescription, mode: com.ts.mobile.sdk.AuthenticatorSessionMode,
               actionContext: com.ts.mobile.sdk.PolicyAction | null, clientContext: any | null): void {
    this.clientContext = clientContext;
  }

  public promiseInput = (): Promise<com.ts.mobile.sdk.InputOrControlResponse<any>> => {
    return new Promise((resolve, reject) => {
      const inputResponse = com.ts.mobile.sdk.PasswordInput.create(this.clientContext.passtext);
      const response = com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(inputResponse);
      resolve(response);
    });
  }

  promiseRecoveryForError(error, validRecoveries, defaultRecovery): Promise<com.ts.mobile.sdk.AuthenticationErrorRecovery> {
    console.log('error promiseRecoveryForError', error);

    return new Promise((resolve, reject) => {
      resolve(com.ts.mobile.sdk.AuthenticationErrorRecovery.Fail);
    });
  }

  promiseCancelAction(validOptions, session, clientContext) {
    const controlRequest = com.ts.mobile.sdk.ControlRequest.create(com.ts.mobile.sdk.ControlRequestType.CancelAuthenticator);
    const response = com.ts.mobile.sdk.InputOrControlResponse.createControlResponse(controlRequest);
    return Promise.resolve(response);
  }

  changeSessionModeToRegistrationAfterExpiration() {
  }

  endSession() {
    console.log('pw autopost endSession');
  }

  getNested(obj, ...args) {
    return args.reduce((obj, level) => obj && obj[level], obj);
  }

  displayAkamaiErrorWithReference(reference) {
    let akamaiErrorMessage = this.transmitUtils.getErrorMessage('akamaiError2');
    if (reference) {
      akamaiErrorMessage = akamaiErrorMessage + ' (' + reference + ')';
    }
    this.alertService.error(akamaiErrorMessage);
  }

}
